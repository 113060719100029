// ~/middleware/checkBlocked.js
import { useSubscriptionStore } from '~/store/subscription/subscription'

export default defineNuxtRouteMiddleware(async (to, from) => {

  const subscriptionStore = useSubscriptionStore()

  if (subscriptionStore.isBlocked) {
    return navigateTo("/assinar");
  }

})
